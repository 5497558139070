<template>
  <div class="page1">
    <div class="content">
      <div style="position: absolute;top: 40px;left: 54px">
        <img style="width: 135px;height: 42px" src="../../../assets/images/pageLogo.png">
      </div>
      <div class="myCard">
        <div class="form">
          <span class="title">{{$primevue.config.language.login.loginPlatform}}</span>
          <span class="title1">{{$primevue.config.language.login.welcomeLogin}}</span>

          <div v-if="display" class="errorMessage">
            <div class="content">
              <div class="ico"><img style="width:20px;height: 20px" src="../../../assets/images/close.png"></div>
              <div class="text">{{$primevue.config.language.login.contactAdministrator}}</div>
            </div>
            <i @click="display=false" style="font-size: 7px;cursor:pointer;" class="pi pi-times"></i>
          </div>
          <div class="p-field">
            <div class="p-input-icon-left p-float-label" style="width:100%;margin-top: 20px;">
              <i class="pi pi-mobile field-icon" />
              <InputText @keydown.enter="keyLogin()" type="text" v-model="username" :class="{'p-invalid':usernameAlert}" style="width:100%;height:48px;" :placeholder="$primevue.config.language.login.telTips" />
            </div>
            <small v-if="usernameAlert" class="p-error">{{ usernameAlert }}</small>
          </div>
          <div class="p-field">
            <div class="p-input-icon-left p-float-label" style="width:100%;margin-top: 30px;">
              <i class="pi pi-lock field-icon" />
              <InputText id="pwdInput" @keydown.enter="keyLogin()" type="password" v-model="password" :class="{'p-invalid':passwordAlert}" style="width:100%;height:48px;" :placeholder="$primevue.config.language.login.pwdTips" />
              <img v-if='eyes' class="eyes" src="@/assets/images/displayeyes.png" alt="" @mousedown="showPwd">
              <img v-else class="eyes" src="@/assets/images/showeyes.png" alt="" @mouseup="displayPwd">
            </div>
            <small v-if="passwordAlert" class="p-error">{{ passwordAlert }}</small>
          </div>
          <div style="width:100%;display:flex;justify-content:space-between">
            <p style="margin-top: 16px">
              <router-link style="color: #12A25D;" to="/forgotPassword" class="word4">{{$primevue.config.language.login.forgotPassword}}</router-link>
            </p>
          </div>
          <Button @click="handleSubmit" class="group4 flex-col">
            <span>{{$primevue.config.language.login.login}}</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <Dialog>
    <template #header>
      <p class="message"><i style="margin-right: 5px"><img style="width:15px;height: 15px" src="../../../assets/images/close.png"></i>无法登录！</p>
    </template>
    {{$primevue.config.language.login.contactAdministrator}}
    <template #footer>
      <Button :label="$primevue.config.language.login.determine" icon="pi pi-check" autofocus @click="display=false" />
    </template>
  </Dialog>

  <Toast />
</template>

<script>
var md5 = require("md5");
export default {
  data() {
    return {
      eyes: true,
      username: null,
      password: null,
      usernameAlert: "",
      passwordAlert: "",
      display: false,
      isLoading: false,
    };
  },
  mounted() {
    if (this.$route.query.forbidden) {
      this.$toast.add({
        severity: this.$primevue.config.language.login.warn,
        summary: this.$primevue.config.language.login.summary,
        detail: this.$primevue.config.language.login.detail,
        life: 3000,
      });
    }
  },
  methods: {
    showPwd() {
      this.eyes = false;
      document.getElementById("pwdInput").type = "text";
    },
    displayPwd() {
      this.eyes = true;
      document.getElementById("pwdInput").type = "password";
    },
    keyLogin() {
      if (event.keyCode == 13) {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      let me = this;

      if (!this.username) {
        this.usernameAlert = this.$primevue.config.language.login.usernameTips;
        if (!this.password) {
          this.passwordAlert = this.$primevue.config.language.login.pwdTips;
          return;
        }
        return;
      } else {
        this.usernameAlert = null;
        if (!this.password) {
          this.passwordAlert = this.$primevue.config.language.login.pwdTips;
          return;
        }
      }
      this.usernameAlert = null;
      this.passwordAlert = null;

      if (!/^1\d{10}$/.test(this.username)) {
        this.usernameAlert = this.$primevue.config.language.login.telWrong;
        return;
      }
      this.usernameAlert = null;

      if (this.username && this.password) {
        this.isLoading = true;
        this.$http(
          "/user/login",
          "get",
          {
            telephone: this.username,
            password: md5(this.password),
          },
          function (response) {
            // 存储token和用户信息
            localStorage.setItem(
              "userinfo",
              JSON.stringify({
                user: response.data.user,
                token: response.data.token,
              })
            );
            let menuList = response.data.resourceList;

            me.$http("project/year", "get", { isArchive: 1 }, (res) => {
              menuList.forEach((item, index) => {
                if (item.id === 31) {
                  res.data.forEach((year) => {
                    menuList[index].children.push({
                      name: year.name + me.$primevue.config.language.login.year,
                      type: 1,
                      icon: "",
                      enable: 0,
                      pid: 31,
                      idx: 3,
                      url: "/project/archive?year=" + year.name,
                    });
                  });
                }
              });
              localStorage.setItem("menuList", JSON.stringify(menuList));
              me.$router.push("/dashboard");
              me.isLoading = false;
            });
          },
          function () {
            me.isLoading = false;
            me.display = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.errorMessage {
  height: 55px;
  width: 100%;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff1f0;
  border: 1px solid #ffccc7;
  border-radius: 2px;
}

.errorMessage i {
  padding-top: 5px;
}

.errorMessage .content {
  display: flex;
  align-items: center;
}

.errorMessage .content .text {
  color: rgba(0, 0, 0, 0.65);
}

.errorMessage .content .ico {
  margin-right: 5px;
}

.message {
  width: 183px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  margin: 0;
}

.page1 {
  width: 100%;
  height: 900px;
  display: block;
  background: url(../../../assets/images/login_bg.jpg) no-repeat;
  background-size: 100%;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.myCard {
  width: 504px;
  height: 60%;
  margin: 0 auto;
  margin-top: 10%;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 20px 0px rgba(0, 50, 49, 0.45);
  display: flex;
}

@media screen and (min-width: 1441px) {
  .page1 {
    width: 100%;
    height: 970px;
    display: block;
    background: url(../../../assets/images/login_bg.jpg) no-repeat;
    background-size: cover;
  }
}

.background1 {
  width: 42.2%;
  height: 100%;
  background: url("../../../assets/images/login_left.png") no-repeat;
  background-size: cover;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.form {
  width: 504px;
  height: 100%;
  padding: 13.11% 8.2% 0 7.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.title {
  width: 100%;
  display: block;
  color: rgba(18, 162, 93, 1);
  font-size: 32px;
  font-family: PingFangSC-Semibold;
  line-height: 45px;
  text-align: center;
}

.title1 {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  display: block;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 20px;
}

.p-field {
  width: 100%;
  margin: 0;
}

.group4 {
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: rgba(53, 183, 117, 1);
  box-shadow: 0px 6px 20px -8px rgba(18, 162, 93, 1);
  align-self: center;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.p-button,
.p-button:hover {
  background: #12a25d;
  border-color: #12a25d;
}
.eyes {
  height: 25px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
</style>
